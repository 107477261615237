import { useRoutes } from 'react-router-dom';
// urls
import { TERMS_OF_SERVICE_URL } from '../constants/common/urlConstants';
// routes
import waitlistRoutes from './waitlist';
import termsOfServiceRoutes from './termsOfService';

// ----------------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { path: '/', ...waitlistRoutes },
    { path: TERMS_OF_SERVICE_URL, ...termsOfServiceRoutes },
  ]);
}
