// ----------------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.MuiButton-contained': {
            color: '#fff',
          },
          borderRadius: theme.shape.borderRadius,
        },
        sizeLarge: { padding: '13px 30px' },
      },
    },
  };
}
