// components
import { Page } from '../components/common';
import { WaitlistHero } from '../components/waitlist';

// -----------------------------------------------------------------------

export default function WaitlistPage() {
  return (
    <Page title='Waitlist | Unyte'>
      <WaitlistHero />
    </Page>
  );
}
