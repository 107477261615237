// ----------------------------------------------------------------------------

export default function DialogTitle(theme) {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: '-0.02em',
          textAlign: 'center',
        },
      },
    },
  };
}
