import { useState } from 'react';
import { useDispatch } from 'react-redux';
// @mui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// actions
import { resetWaitlist } from '../../actions/waitlist';
// components
import { ButtonPill, CustomDialog, Hero } from '../common';
//
import WaitlistForm from './WaitlistForm';

// ----------------------------------------------------------------------------

const TitleStyle = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  lineHeight: '1.5rem',
  fontWeight: 700,
  marginBottom: 12,
  color: theme.palette.primary.main,
}));

// ----------------------------------------------------------------------------

export default function WaitlistHero() {
  return (
    <Hero sx={{ marginTop: { xs: '-65px', md: 'initial' } }}>
      <Box textAlign={{ xs: 'center', md: 'initial' }}>
        <TitleStyle>Unlocking Possibilities</TitleStyle>
        <Typography variant="h1" sx={{ color: 'common.white' }} gutterBottom>
          Get Insured,
          <span style={{ display: 'block' }}>live with Confidence</span>
        </Typography>

        <Typography
          variant="base1Semibold"
          color="white"
          display="block"
          textAlign={{ xs: 'inherit', md: 'justify' }}
          sx={{
            width: { xs: '100%', md: '50%', lg: '40%' },
            marginTop: 1,
          }}
        >
          At Unyte, we take care of your tomorrow so you can enjoy today, with
          amazing insurance packages tailored to your needs. Simple,
          stress-free.
        </Typography>

        <WaitlistCTA />
      </Box>
    </Hero>
  );
}

// ----------------------------------------------------------------------------

function WaitlistCTA() {
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => {
    dispatch(resetWaitlist());
    setDialogOpen(false);
  };
  return (
    <Box sx={{ mt: 3, mb: 1 }}>
      <ButtonPill
        variant="contained"
        color="primary"
        size="large"
        onClick={handleDialogOpen}
      >
        Join the Waitlist
      </ButtonPill>

      <CustomDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        dialogTitle="Join the Waitlist"
      >
        <WaitlistForm />
      </CustomDialog>
    </Box>
  );
}

// ----------------------------------------------------------------------------
