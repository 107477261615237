// ----------------------------------------------------------------------------

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
          '@media (min-width:600px)': {
            padding: '20px',
          },
        },
      },
    },
  };
}
