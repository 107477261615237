//
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Material UI
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// constants
import { CLEAR_APP_NOTIFICATION } from '../../constants/common/appNotificationConstants';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

/**
 * Component for displaying application's notifications using MUI Snackbar
 * and Alert under the hood.
 * @returns Snackbar notification
 */
const AppNotification = () => {
  const { open, message, severity } = useSelector(
    (state) => state.appNotification
  );

  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch({ type: CLEAR_APP_NOTIFICATION });
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={null}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant='filled'
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppNotification;
