import {
  CLEAR_APP_NOTIFICATION,
  DISPLAY_APP_NOTIFICATION,
} from '../../constants/common/appNotificationConstants';

export const appNotificationReducer = (
  state = { open: false, message: '', severity: 'info' },
  action
) => {
  switch (action.type) {
    case DISPLAY_APP_NOTIFICATION:
      return {
        open: true,
        message: action.payload.message
          ? action.payload.message
          : action.payload || '',
        severity: action.payload.severity || 'info',
      };

    case CLEAR_APP_NOTIFICATION:
      return { open: false, message: '', severity: state.severity || 'info' };

    default:
      return state;
  }
};
