import { alpha } from '@mui/material/styles';

const NEUTRAL = {
  main: '#efefef',
  '00': '#ffffff',
  '01': '#FCFCFC',
  '02': '#F4F4F4',
  '03': '#EFEFEF',
  '04': '#6F767E',
  '05': '#33383F',
  '06': '#272B30',
  '07': '#050606',
  '08': '#000000',
};

const PRIMARY = {
  main: '#25D366',
  contrastText: '#fff',
};

const SECONDARY = {
  main: '#fff',
  contrastText: '#000',
};

const TEXT = {
  primary: '#050606',
  secondary: '#031731',
  grey: '#12141d',
};

const GRADIENT = {
  primary: 'linear-gradient(101.34deg, #25D366 0.69%, #009136 96.34%)',
  contrastText: '#fff',
};

const palette = {
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  neutral: { ...NEUTRAL },
  text: { ...TEXT },
  background: {
    paper: '#fff',
    neutral: NEUTRAL.main,
    primary: alpha(PRIMARY.main, 0.05),
  },
  divider: 'rgba(230, 232, 236, 1)',
  gradient: { ...GRADIENT },
};

export default palette;
