//
import Button from './Button';
import Card from './Card';
import CssBaseline from './CssBaseline';
import Dialog from './Dialog';
import DialogTitle from './DialogTitle';
import Typography from './Typography';

// ----------------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Button(theme),
    Card(theme),
    CssBaseline(theme),
    Dialog(theme),
    DialogTitle(theme),
    Typography(theme)
  );
}
