import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useScrollTrigger } from '@mui/material';

/**
 * Adds elevation to its children when window is scrolled
 * @param {element} children
 * @returns {element} Cloned children with an elevation when scrolled
 */
export default function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  /** Children component */
  children: PropTypes.element.isRequired,
};
