// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { AppNotification } from './components/notifications';
import { ErrorBoundary, ScrollToTop } from './components/utils';

export default function App() {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <ScrollToTop />
        <AppNotification />
        <Router />
      </ErrorBoundary>
    </ThemeProvider>
  );
}
