import { useMemo } from 'react';
import PropTypes from 'prop-types';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
//
import ComponentsOverrides from './overrides';
import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
      shape: { borderRadius: 8 },
      shadows,
      customShadows,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline>{children}</CssBaseline>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
