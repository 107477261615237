import { Suspense } from 'react';
// @mui
import { Box, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

const SuspenseFallback = ({ children }) => (
  <Suspense
    fallback={
      <Box
        display="flex"
        height="90vh"
        justifyContent="center"
        alignItems="center"
        bgcolor="#f9f9f9"
      >
        <CircularProgress size="50px" thickness={4} />
      </Box>
    }
  >
    {children}
  </Suspense>
);

export default SuspenseFallback;
