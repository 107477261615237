import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/logoOnly';
// constants
import { TERMS_OF_SERVICE_URL } from '../constants/common/urlConstants';
// components
import SuspenseFallback from './SuspenseFallback';
// dynamic
const TermsOfServicePage = lazy(() => import('../pages/TermsOfServicePage'));

// ----------------------------------------------------------------------------

const termsOfServiceRoutes = {
  element: <LogoOnlyLayout />,
  children: [
    {
      index: true,
      element: (
        <SuspenseFallback>
          <TermsOfServicePage />
        </SuspenseFallback>
      ),
    },
    { path: '*', element: <Navigate to={TERMS_OF_SERVICE_URL} /> },
  ],
};

export default termsOfServiceRoutes;
