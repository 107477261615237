import {
  WAITLIST_CREATE_FAIL,
  WAITLIST_CREATE_REQUEST,
  WAITLIST_CREATE_RESET,
  WAITLIST_CREATE_SUCCESS,
} from '../../constants/waitlist/waitlistConstants';

export const waitlistJoinReducer = (state = {}, action) => {
  switch (action.type) {
    case WAITLIST_CREATE_REQUEST:
      return { loading: true };

    case WAITLIST_CREATE_SUCCESS:
      return { loading: false, success: true, waitlist: action.payload };

    case WAITLIST_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case WAITLIST_CREATE_RESET:
      return {};

    default:
      return state;
  }
};
