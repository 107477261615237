// @mui
import { Box, Container, AppBar, Toolbar, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { Logo } from '../../components/common';

// ----------------------------------------------------------------------------

const APP_BAR_DESKTOP = 80;
const APP_BAR_MOBILE = 65;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
  },
}));

// ----------------------------------------------------------------------------

export default function WaitlistNavbar() {
  return (
    <AppBar
      color="secondary"
      sx={{
        bgcolor: { xs: 'transparent', md: 'common.white' },
      }}
      elevation={{ xs: 0, md: 2 }}
    >
      <Container maxWidth="lg">
        <ToolbarStyle disableGutters>
          <LogoDesktop />
          <LogoMobile />
        </ToolbarStyle>
      </Container>
      <Divider sx={{ display: { xs: 'none', md: 'initial' } }} />
    </AppBar>
  );
}

// ----------------------------------------------------------------------------

function LogoDesktop() {
  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Logo />
    </Box>
  );
}

// ----------------------------------------------------------------------------

function LogoMobile() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'center',
        mt: 4,
      }}
    >
      <Logo variant="dark" />
    </Box>
  );
}
