import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// actions
import { createWaitlist } from '../../actions/waitlist';
// helpers
import { detectBrowser, detectDevice } from '../../helpers';

// ----------------------------------------------------------------------------

const WaitlistSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name is too short')
    .max(50, 'Name is too long')
    .required('Full name is required'),
  email: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  phone: Yup.string(),
});

// ----------------------------------------------------------------------------

export default function WaitlistForm() {
  const dispatch = useDispatch();

  // waitlist state and form validation
  const { loading, error, success } = useSelector(
    (state) => state.waitlistJoin
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validationSchema: WaitlistSchema,
    onSubmit: ({ name, email, phone }) => {
      dispatch(
        createWaitlist({
          browser_type: detectBrowser(),
          browser_version: navigator.userAgent,
          device_id: '',
          device_type: detectDevice(),
          os_type: navigator.userAgentData
            ? navigator.userAgentData.platform
            : navigator.userAgent,
          os_version: navigator.userAgent,
          phone_number: phone,
          location,
          name,
          email,
        })
      );
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  // --------------------------------------------------------------------------
  //                            Location
  // --------------------------------------------------------------------------

  //  Location state
  const [location, setLocation] = useState('');

  // Location functions
  const showPosition = useCallback((position) => {
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  }, []);

  const handleLocationError = useCallback((error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setLocation('User denied the request for Geolocation.');
        break;

      case error.POSITION_UNAVAILABLE:
        setLocation('Location information is unavailable.');
        break;

      case error.TIMEOUT:
        setLocation('The request to get user location timed out.');
        break;

      default:
        setLocation('An unknown error occurred.');
    }
  }, []);

  const getUserLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(showPosition, handleLocationError);
  }, [handleLocationError, showPosition]);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  // success handler

  const navigate = useNavigate();

  useEffect(() => {
    if (success) navigate(0);
  }, [success, navigate]);

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="waitlist-form"
      >
        <Stack spacing={1} mt={1}>
          <TextField
            placeholder="Enter your Name"
            label="FullName"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name) || Boolean(error)}
            helperText={(touched.name && errors.name) || ' '}
            fullWidth
          />
          <TextField
            placeholder="Enter your Email Address"
            label="Email Address"
            type="email"
            fullWidth
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email) || Boolean(error)}
            helperText={(touched.email && errors.email) || ' '}
          />
          <TextField
            placeholder="Enter your Phone Number"
            label="Phone Number"
            type="tel"
            fullWidth
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={(touched.phone && errors.phone) || ' '}
          />
        </Stack>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={loading}
          size="large"
        >
          Continue
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
