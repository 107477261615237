import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// ----------------------------------------------------------------------------

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dialogTitle: PropTypes.string,
};

export default function CustomDialog({
  open,
  handleClose,
  dialogTitle,
  children,
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="dialog-title"
      maxWidth="xs"
      scroll="body"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          position: 'relative',
          right: 8,
          top: 8,
        }}
      >
        <IconButton aria-label="close dialog" onClick={handleClose}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
      {dialogTitle && <CustomDialogTitle>{dialogTitle}</CustomDialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

// ----------------------------------------------------------------------------

CustomDialogTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export function CustomDialogTitle({ children }) {
  return (
    <DialogTitle id="dialog-title" sx={{ mt: -3 }}>
      {children}
    </DialogTitle>
  );
}
