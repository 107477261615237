import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import WaitlistFooter from './WaitlistFooter';
import WaitlistNavbar from './WaitlistNavbar';

// ----------------------------------------------------------------------------

const APP_BAR_DESKTOP = 80;
const APP_BAR_MOBILE = 65;

const RootStyle = styled('div')({
  overflow: 'hidden',
});

const MainStyle = styled('main')(({ theme }) => ({
  marginTop: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    marginTop: APP_BAR_DESKTOP,
  },
}));

export default function WaitlistLayout() {
  return (
    <RootStyle>
      <WaitlistNavbar />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <footer>
        <WaitlistFooter />
      </footer>
    </RootStyle>
  );
}
