import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// assets
import serverDownIllustration from '../assets/illustrations/serverDownIllustration.svg';
// components
import { Page } from '../components/common';

export default function ErrorPage() {
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };
  return (
    <Page title="Oops | UnyteAfrica Organization">
      <main>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          padding={5}
        >
          <div>
            <img
              src={serverDownIllustration}
              alt="Quality Assurance Engineer looking at a notepad to resolve the problem"
              width="500"
              height="358"
              style={{ maxWidth: '100%', height: 'auto', marginBottom: '2rem' }}
            />
            <Typography
              variant="h4"
              component="h1"
              textAlign="center"
              display="block"
              color="neutral.04"
              gutterBottom
            >
              Oops, there was an error on our end.
            </Typography>
            <Typography
              variant="title1Semibold"
              textAlign="center"
              display="block"
              color="primary"
              sx={{ cursor: 'pointer' }}
              role="button"
              onClick={refreshPage}
              gutterBottom
            >
              Please try reloading the page
            </Typography>
            <Typography
              variant="subtitle2"
              color="neutral.04"
              textAlign="center"
            >
              If this problem persists, contact us at{' '}
              <Link
                href="mailto:tech@unyte.africa"
                target="_blank"
                rel="noopener"
                underline="hover"
                color="primary"
                textAlign="center"
              >
                tech@unyte.africa
              </Link>
            </Typography>
          </div>
        </Box>
      </main>
    </Page>
  );
}
