import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import LogoOnlyFooter from './LogoOnlyFooter';
import LogoOnlyNavbar from './LogoOnlyNavbar';

// ----------------------------------------------------------------------------

const RootStyle = styled('div')({
  overflow: 'hidden',
});

export default function LogoOnlyLayout() {
  return (
    <RootStyle>
      <LogoOnlyNavbar />
      <main style={{ paddingBottom: '5rem' }}>
        <Outlet />
      </main>
      <footer>
        <LogoOnlyFooter />
      </footer>
    </RootStyle>
  );
}
