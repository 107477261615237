// material
import { Container, Paper, Stack, Typography } from '@mui/material';
import { FacebookOutlined, Instagram, Twitter } from '@mui/icons-material';

// ----------------------------------------------------------------------------

export default function WaitlistFooter() {
  return (
    <Paper variant='outlined' square sx={{ py: 2 }}>
      <Container maxWidth='lg'>
        <Stack
          spacing={3}
          direction='row'
          justifyContent='space-between'
          textAlign='center'
        >
          <Typography variant='caption3' color='#777E90'>
            Copyright &copy; 2021 unyte. All rights reserved
          </Typography>

          <Stack direction='row' justifyContent='center' spacing={3}>
            <FacebookOutlined fontSize='small' color='action' />
            <Twitter fontSize='small' color='action' />
            <Instagram fontSize='small' color='action' />
          </Stack>
        </Stack>
      </Container>
    </Paper>
  );
}
