import PropTypes from 'prop-types';
// @mui
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// assets
import HeroImage from '../../assets/pictures/Hero.png';

// ----------------------------------------------------------------------------

const HeroStyle = styled(Box)({
  backgroundImage: `url(${HeroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  width: '100%',
  position: 'relative',
});

const HeroContentStyle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

// ----------------------------------------------------------------------------

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  /** @default 100vh */
  heroHeight: PropTypes.string,

  /**  @default 100vh */
  heroContentHeight: PropTypes.string,
  sx: PropTypes.object,
};

Hero.defaultProps = {
  heroHeight: '100vh',
  heroContentHeight: '110vh',
};

export default function Hero({ children, heroHeight, heroContentHeight, sx }) {
  return (
    <HeroStyle height={heroHeight} sx={{ ...sx }}>
      <Box>
        <Container>
          <HeroContentStyle height={heroContentHeight}>
            {children}
          </HeroContentStyle>
        </Container>
      </Box>
    </HeroStyle>
  );
}
