import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
//------------------------------------------------------------
import rootReducer from './reducers/rootReducer';

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const preloadedState = {};

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
