import { combineReducers } from 'redux';

import { waitlistJoinReducer } from './waitlist';
import { appNotificationReducer } from './notifications';

const rootReducer = combineReducers({
  appNotification: appNotificationReducer,
  waitlistJoin: waitlistJoinReducer,
});

export default rootReducer;
