import axios from 'axios';
import {
  WAITLIST_CREATE_FAIL,
  WAITLIST_CREATE_REQUEST,
  WAITLIST_CREATE_RESET,
  WAITLIST_CREATE_SUCCESS,
} from '../../constants/waitlist/waitlistConstants';
import { displayAppNotification } from '../notifications';

// const API_ENDPOINT = `https://us-east1-unyte-project.cloudfunctions.net/store_waitlist_open?key=${process.env.REACT_APP_WAITLIST_API_KEY}`;
const API_ENDPOINT = `https://us-east1-unyte-project.cloudfunctions.net/store_waitlist_open?key=xbsa3j_4hdqw9djqwijdq3w67`;

export const createWaitlist = (userInfo) => async (dispatch) => {
  try {
    dispatch({ type: WAITLIST_CREATE_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const API_URL =
      process.env.NODE_ENV === 'test'
        ? `${API_ENDPOINT}&test=true`
        : API_ENDPOINT;

    const { data } = await axios.post(API_URL, userInfo, config);

    dispatch({ type: WAITLIST_CREATE_SUCCESS, payload: data });
    dispatch(
      displayAppNotification(
        'Congratulations!! You have joined the waitlist successfully.',
        'success',
        null
      )
    );
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: WAITLIST_CREATE_FAIL,
      payload: errorMsg,
    });
    dispatch(displayAppNotification(errorMsg, 'error'));
  }
};

export const resetWaitlist = () => (dispatch) => {
  dispatch({ type: WAITLIST_CREATE_RESET });
};
