import PropTypes from 'prop-types';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------------

ButtonPill.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.object,
  /**
   * Button size
   * @default medium
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   *  Button variant.
   * @default contained
   */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  /**
   *  Button's color.
   * @default primary
   */
  color: PropTypes.string,
};

ButtonPill.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'medium',
};

export default function ButtonPill({
  color,
  children,
  variant,
  sx,
  size,
  ...rest
}) {
  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      sx={{ borderRadius: 24, ...sx }}
      {...rest}
    >
      {children}
    </Button>
  );
}
