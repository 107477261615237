import PropTypes from 'prop-types';
// @mui
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------------

ArticleSpacing.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default function ArticleSpacing({ children, sx }) {
  return (
    <Stack spacing={{ xs: 1, md: 4 }} sx={{ ...sx }}>
      {children}
    </Stack>
  );
}
