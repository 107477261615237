import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// logos
import unyteHorizontalMain from '../../assets/logo/SVG/unyte_horizontal_main.svg';
import unyteHorizontalDark from '../../assets/logo/SVG/unyte_horizontal_dark.svg';
import { HOME_URL } from '../../constants/common/urlConstants';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.oneOf(['light', 'dark']),
};

Logo.defaultProps = {
  disabledLink: false,
  variant: 'light',
};

export default function Logo({ disabledLink, variant, style }) {
  const logo = (
    <img
      src={variant === 'light' ? unyteHorizontalMain : unyteHorizontalDark}
      alt="Unyte Logo"
      style={{
        maxWidth: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
      width={108}
      height={36}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={HOME_URL}>{logo}</RouterLink>;
}
