import { lazy } from 'react';
// layouts
import WaitlistLayout from '../layouts/waitlist';
// pages
import WaitlistPage from '../pages/WaitlistPage';
// components
import SuspenseFallback from './SuspenseFallback';
// dynamic
const Page404 = lazy(() => import('../pages/Page404'));

// ----------------------------------------------------------------------------

const waitlistRoutes = {
  element: <WaitlistLayout />,
  children: [
    {
      index: true,
      element: <WaitlistPage />,
    },
    {
      path: '*',
      element: (
        <SuspenseFallback>
          <Page404 />
        </SuspenseFallback>
      ),
    },
  ],
};

export default waitlistRoutes;
