import {
  CLEAR_APP_NOTIFICATION,
  DISPLAY_APP_NOTIFICATION,
} from '../../constants/common/appNotificationConstants';

/**
 * Display notification with message and severity
 * @param {string} message Details about the notification
 * @param {String} severity 'error' | 'warning' | 'info' | 'success'
 * @param {number} [autoHideDuration=6500] Amount of time in ms to display notification after which it is cleared, default is 6500ms (6.5s). Add +1000ms (+1s) for every 120 words for best practice. Pass null to leave notification displayed until user closes it manually with close button
 */
export const displayAppNotification =
  (message, severity, autoHideDuration) => (dispatch) => {
    dispatch({
      type: DISPLAY_APP_NOTIFICATION,
      payload: { message, severity },
    });

    // Clear or Close notification after a period of time in ms
    if (autoHideDuration !== null) {
      setTimeout(() => {
        dispatch({ type: CLEAR_APP_NOTIFICATION });
      }, autoHideDuration || 6500);
    }
  };
