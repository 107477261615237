// @mui
import { Box, Container, AppBar, Toolbar, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { Logo } from '../../components/common';
import { ElevationScroll } from '../../components/utils';

// ----------------------------------------------------------------------------

const APP_BAR_DESKTOP = 80;
const APP_BAR_MOBILE = 65;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
  },
}));

// ----------------------------------------------------------------------------

export default function LogoOnlyNavbar() {
  return (
    <ElevationScroll>
      <AppBar color="secondary" elevation={{ xs: 0, md: 2 }}>
        <Container maxWidth="sm">
          <ToolbarStyle>
            <Box
              sx={{
                flexGrow: 1,
                width: '100%',
                display: { xs: 'flex', md: 'block' },
                justifyContent: 'center',
              }}
            >
              <Logo />
            </Box>
          </ToolbarStyle>
        </Container>
        <Divider />
      </AppBar>
    </ElevationScroll>
  );
}
